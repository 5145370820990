.wallet-button {
    background-color: black !important;
    color: white !important;
    font-family: "SFPixelate" !important;
    font-size: 30px !important;
    height: 75px !important;
    line-height: 40px !important;
    padding: 0 0px !important;
    border-radius: 0px !important;
    text-decoration: underline !important;
}

.wallet-button:hover {
    color: white !important;
    text-decoration: underline !important;
}

.wallet-adapter-button-trigger {
    background-color: #512da8;
}

.wallet-disconnect-button {
    background-color: black !important;
    color: white !important;
    font-family: "SFPixelate" !important;
    font-size: 16px !important;
    height: 20px !important;
    line-height: 20px !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
}

.mobile-wallet-button {
    background-color: black !important;
    color: white !important;
    font-family: "SFPixelate" !important;
    font-size: 15px !important;
    height: 20px !important;
    line-height: 20px !important;
    padding: 0 0px !important;
    border-radius: 0px !important;
}


@media screen and (max-width:900px) {
    .wallet-disconnect-button {
        background-color: black !important;
        color: white !important;
        font-family: "SFPixelate" !important;
        font-size: 10px !important;
        height: 20px !important;
        line-height: 20px !important;
        padding-left: 0px !important;
        margin-left: 1px !important;
    }  
}