.navigationMobileBody{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
}
.navigationBtns{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    gap:10px;
    margin-top: 2px;
}