/* Define the background color of the entire player and placement */
.music-player {
    color: #fff; /* White text */
    position: absolute;
    top: -15px;
    left: 51%;
    transform: translateX(-50%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    transition: none;
}

/* Style the audio controls */
.music-player audio {
    width: 100%;
    height: 10px;
}

/* Style the play/pause button */
.music-player audio::-webkit-media-controls-play-button,
.music-player audio::-webkit-media-controls-pause-button {
    display: block;
    background-color: #fff; /* White */
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 7px;
}

/* Style the track progress bar */
.music-player audio::-webkit-media-controls-timeline-container,
.music-player audio::-webkit-media-controls-timeline-progress-bar {
    display: none;
}

/* Hide mute button */
button[aria-label="Mute"] {
    display: none;
}

button[aria-label="Rewind"] {
    display: none;
}

button[aria-label="Unmute"] {
    display: none;
}

button[aria-label="Forward"] {
    display: none;
}

/* Style the volume and mute buttons */
.music-player audio::-webkit-media-controls-volume-slider,
.music-player audio::-webkit-media-controls-mute-button {
    display: none; /* Hide the default buttons */
    color: #fff;
}

/* Style the custom volume and mute buttons */
.music-player .volume-button,
.music-player .mute-button {
    display: block;
    background-color: #fff; /* White */
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 5px;
    color: #fff;
}

/* Style the volume slider */
.music-player .volume-slider {
    color: #fff;
    width: 100%;
    height: 5px;
    background-color: #fff; /* White */
}

.rhap_progress-section {
    display: none;
}
.rhap_progress-container {
    display: none;
}

.rhap_container {
    transform: scale(1.5);
    width: fit-content;
}

.music-player .rhap_play-pause-button,
.music-player .rhap_skip-button,
.music-player .rhap_rewind-button,
.music-player .rhap_forward-button {
    /* button styles here */
    width: 50px ;
}

.music-player button {
    padding: 0;
    margin: 0;
    /* width: 20px;
  height: 20px; */
}

.music-player svg {
    width: 100%;
    height: 100%;
}

.rhap_volume-bar,
.rhap_volume-indicator {
    display: none;
}

button[aria-label="Enable loop"] {
    display: none;
}

@media only screen and (max-width: 495px) {
    .rhap_main {
        font-size: 14px;
    }

    .rhap_controls-section {
        margin-top: 0px;
    }

    .rhap_volume-bar-area {
        height: 3px;
    }

    .music-player .rhap_play-pause-button,
    .music-player .rhap_skip-button,
    .music-player .rhap_rewind-button,
    .music-player .rhap_forward-button {
        /* button styles here */
        width: 50px !important;
        height: 20px;
        
    }

    .music-player {
        top: -8px !important;
        left: 54% !important;
        transform: translateX(-50%);
        position: absolute;
    }
}
@media only screen and (max-width: 900px) {
    .music-player {
        top: -16px;
        left: 52%;
        transform: translateX(-50%);
        position: absolute;
    }
    .music-player .rhap_play-pause-button,
.music-player .rhap_skip-button,
.music-player .rhap_rewind-button,
.music-player .rhap_forward-button {
    /* button styles here */
    width: 40px ;

}

}
@media only screen and (max-width: 350px) {
    .music-player {
        top: -12px;
        left: 54% !important;
        transform: translateX(-50%);
        position: absolute;
    }
    .music-player .rhap_play-pause-button,
    .music-player .rhap_skip-button,
    .music-player .rhap_rewind-button,
    .music-player .rhap_forward-button {
        /* button styles here */
        width:50px !important;
    }
}