a.one {
    outline: none;
    text-decoration: none;
    padding: 2px 1px 0;
}

a.one:link {
    color: white;
    border-bottom: 1px solid;
}

a.one:visited {
    color: white;
    border-bottom: 1px solid;
}

a.one:focus {
    color: white;
    border-bottom: 1px solid;
}

a.one:hover {
    color: white;
    border-bottom: 1px solid;
}

a.one:active {
    color: white;
    border-bottom: 1px solid;
}
.rowElementsTitle {
    width: 27%;
}
/* Scroll effect for website */
body {
    height: 100vh !important;
    overflow-y: scroll !important;
}

/* HOME PAGE */
@media screen and (max-width: 1200px) {
    .rowElements {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
        width: 100% !important;
    }
    .rowElementsTitle {
        width: 60%;
    }
}

/* STATS */
@media screen and (max-width: 1200px) {
    .responsiveGraph {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
        width: 80% !important;
        text-align: center !important;
    }
    .responsiveGraph div {
    }
    .lineGraphVstack {
        width: 100% !important;
        margin-top: 40px !important;
    }
    .responsivePage {
        position: relative;
        display: grid;
        place-items: center;
    }
}
